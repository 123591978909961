@import "../../../assets/sass/helper";


.btn-link {
    position: relative;
    display: table;
    line-height: 20px;
    color: $heading-color;

    &::before {
        content: "";
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 1px;
        background: $border-color;
        transform-origin: left;
        transition: transform 0.2s ease-out;
    }

    .text {
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 1px;
    }

    &:hover {
        color: $heading-color;

        &::before {
            transform-origin: right;
            transform: scaleX(0);
        }
    }
}