@import "../../assets/sass/helper";

.box-info-contact {
  position: relative;

  @include media_768 {
    padding: 0;
    margin-bottom: 50px;
    background-color: transparent;
  }


  ul li {

      &:not(:first-child){
        margin-top: 20px;
      }

      span {
        color: $heading-color;
        font-weight: 600;
        font-size: 13px;
        text-transform: uppercase;
        min-width: 120px;

        @include media_400 {
          min-width: auto;
          margin-right: 15px;
        }
      }

      a {
        position: relative;
        transition: color 0.3s ease-out;
        line-height: 1.7;


        &:after , &:before{
          content: "";
          position: absolute;
          height: 1px;
          width: 100%;
          bottom: 0;
          left: 0;
          background-color: $border-color;
          transition: transform 0.4s cubic-bezier(0.28, 0.38, 0, 0.81);
        }
        &::before {
          transform-origin: 100% 50%;

        }

        &:after {
          transform: scaleX(0);
          transform-origin: 0 50%;
          transition-delay: 0.2s;
        }

        &:hover {
          &::before {
            transform: scaleX(0);
          }

          &:after {
            transform: scaleX(1);
          }
        }
      }

  }
}
.box-info-contact.p-30 + .form-box{
  @include media_991(min){
    padding: 30px;
  }
}
.submit-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content :flex-start;
    gap: 1.5rem; /* You may adjust the value as needed */
  
    @media (min-width: 640px) {
      flex-direction: row;
      justify-content: space-between;
      gap: 0;
    }
}