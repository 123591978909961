@import "../../assets/sass/helper";
.wrapper-brand{

    .brand-item{
        position: relative;
        display: flex;
        align-items: center;
        text-align: center;
        min-height: 240px;
        padding: 15px;
        img {
            -webkit-transition: all 0.2s;
            -o-transition: all 0.2s;
            transition: all 0.2s;
            max-width: 180px;
            margin: auto;
        }
        &:hover {
            img {
                -webkit-transform: scale(0);
                -ms-transform: scale(0);
                transform: scale(0);
                opacity: 0;
            }
            .char {
                -webkit-animation: fadeInUp 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
                animation: fadeInUp 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
                -webkit-animation-delay: calc(20ms * var(--char-index));
                animation-delay: calc(20ms * var(--char-index));
              }
        }
    }
 
    .box-img {
        margin: auto;
    }
    .info {
        top: 0;
        z-index: 2;

        & , .entry{
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
        }
        .entry {
            padding: 10%;
            display: flex;
            vertical-align: middle;
            justify-content: center;
            align-items: center;
            transition: opacity 0.3s cubic-bezier(0.77, 0, 0.175, 1);
            opacity: 0;

            div {
                width: 100%;

                h5 {
                    font-size: 15px;
                    margin-bottom: 20px;
                }

                a {
                    letter-spacing: 2px;
                    font-size: 16px;
                }
            }
        }

        &:hover {
            .entry {
                left: 0;
                top: 0;
                opacity: 1 !important;
                -webkit-transition: all 0;
                -o-transition: all 0;
                transition: all 0;
                .splitting {
                    .char {
                        -webkit-animation: wave-text 0.4s ease-in-out ;
                        animation: wave-text 0.4s ease-in-out;
                        -webkit-animation-delay: calc(30ms * var(--char-index));
                        animation-delay: calc(30ms * var(--char-index));
                    }
                }
            }

        }
    }
}

.clients {
	.brands {
		&:hover {
			img {
				-webkit-transform: scale(0);
				-ms-transform: scale(0);
				transform: scale(0);
				opacity: 0;
			}
			.link {
				opacity: 1 !important;
				-webkit-transition: all 0;
				-o-transition: all 0;
				transition: all 0;
			}
			.char {
				-webkit-animation: fadeInUp 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
				animation: fadeInUp 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
				-webkit-animation-delay: calc(20ms * var(--char-index));
				animation-delay: calc(20ms * var(--char-index));
			}
		}
		.item {
			text-align: center;
			border: 1px solid rgba(255, 255, 255, 0.08);
			border-radius: 10px;
			height: 160px;
			line-height: 160px;
		}
		.img {
			position: relative;
			img {
				-webkit-transition: all 0.4s;
				-o-transition: all 0.4s;
				transition: all 0.4s;
				max-width: 75px;
				margin: auto;
			}
			.link {
				font-size: 13px;
				width: -webkit-max-content;
				width: -moz-max-content;
				width: max-content;
				position: absolute;
				top: 0;
				left: 50%;
				-webkit-transform: translateX(-50%);
				-ms-transform: translateX(-50%);
				transform: translateX(-50%);
				opacity: 0;
				-webkit-transition: all 0.3s;
				-o-transition: all 0.3s;
				transition: all 0.3s;
			}
		}
	}
	.bord {
		.item {
			margin-bottom: 30px;
		}
	}
}

.splitting {
	.char {
		-webkit-animation: fadeInUp 0.4s cubic-bezier(0.3, 0, 0.7, 1) both;
		animation: fadeInUp 0.4s cubic-bezier(0.3, 0, 0.7, 1) both;
		-webkit-animation-delay: calc(30ms * var(--char-index));
		animation-delay: calc(30ms * var(--char-index));
	}
}
.splitting {
	.whitespace {
		width: 5px;
	}
}



@-webkit-keyframes wave-text {
    00% {
      transform: translateY(0em);
    }
    60% {
      transform: translateY(-0.6em);
    }
    100% {
      transform: translateY(0em);
    }
  }
   
  @keyframes wave-text {
    00% {
      transform: translateY(0em);
    }
    60% {
      transform: translateY(-0.6em);
    }
    100% {
      transform: translateY(0em);
    }
  }