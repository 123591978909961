@import "../../../assets/sass/helper";

.btn-project {
    position: relative;
    color: $heading-color;
    text-decoration: none;
    text-align: center;
    width: 100%;
    padding: 8px 0;

    &::after ,
    &::before  {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        background-color: $border-color;
    }

    &::before {
        top: 0;
        transition: top 0.4s cubic-bezier(0.14, 0.7, 0.3, 1) 0s;
    }

    &::after {
        bottom: 0;
        transition: bottom 0.4s cubic-bezier(0.14, 0.7, 0.3, 1) 0s;
    }

    .text {
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 4px;
        text-transform: uppercase;
    }

    &:hover {
        color: $heading-color;

        &::before {
            top: 10%;
        }
    
        &::after {
            bottom: 10%;
        }
    }
}
