@import "../../assets/sass/helper";

.social-one:not(.social-network) li {

  display: inline-block;
  list-style: none;
  margin-right: 5px;

  a {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $heading-color;
    border-radius: 50%;
    letter-spacing: 0;
    border: 1px solid $border-color;
    height: 35px;
    width: 35px;
    text-align: center;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;



    svg {
      position: relative;
      z-index: 2;
      fill: $heading-color;
      width: 18px;
      height : 18px;
      cursor: pointer;
      transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    }

    &:hover {

      svg {
        color: $heading-color;
        transform: rotate(360deg);
      }
    }
  }

}

.main-social {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 30px;
  left: 50px;
  z-index: 2;


  .social-icon {
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    transition-duration: 0.6s;

    svg {
      fill: $heading-color;
      width: 16px;
      height: 16px;
      cursor: pointer;
    }

    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 1px;
      background-color: $heading-color;
      opacity: 0.2;
      top: 8px;
      left: 35px;
      transition: width 0.5s 0.3s;
    }
  }

  .social-network {
    margin-left: 75px;
    overflow: hidden;
    will-change: transform;
    display: flex;
    align-items: center;

    li {
      opacity: 0;
      visibility: hidden;
      transform-origin: top left;
      font-size: 16px;
      margin-right: 30px;
      transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      transform: translateY(5px) rotate(10deg);

      a {
        width: 100%;
      }

      svg {
        fill: $heading-color;
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }
  }

  &:hover {
    .social-icon:after {
      width: 40px;
      transition-delay: 0s;
    }

    .social-network {
      li {
        opacity: 1;
        visibility: visible;
        transform: none;
        @include transition-delay(0.25s , var(--item-index))
      }
    }
  }
}
