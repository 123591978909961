@import "../../assets/sass/helper";

.next-page {
  position: relative;
  background-color: $assistant-color;
  overflow: hidden;

  &:before {
    content: "";
    width: 0;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    background-color: $bg-color;
    transition: width 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  }

  .c-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between !important;
    a {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: space-between !important;
      overflow: hidden;
      
      @include media_768(min) {
        flex-flow: row;
        justify-content: end;
        align-items: center;
      }

      @include media_768() {
        flex-direction: column;
        .btn-whitee {
          margin-top: 10px;
        }
      }

      .btn-whitee{
        button{
          background-color: #fff !important;
            border-radius: 100px;
            color: #000 !important;
            font-size: large !important;
            font-weight: 600 !important;
        }
      }

      .hiring {
        position: relative;
        display: block;
        font-size: 42px;
        font-weight: 800;
        line-height: 1.2;
        letter-spacing: 0.01em;
        color: transparent;
        -webkit-text-stroke: 1px $heading-color;
        text-transform: uppercase;
        transition: color 0.5s cubic-bezier(0.77, 0, 0.175, 1);
        max-width: 70%;
      }
    }
  }

  &:hover {
    &:before {
      width: 100%;
    }

    a {
      .hiring {
        color: $heading-color;
      }
    }
  }
}

